import { createSlice } from "@reduxjs/toolkit";
import { TutorialType } from "components/Tutorial/components/TutorialItem";
import { AccountSelectMenu } from "pages/Account/Account";
import { EventDispatchLogout } from "router/Router";

interface ISnackbar {
  message: string;
  icon: any;
  type?: SnackbarType;
}

export enum SnackbarType {
  Success,
  Fail,
  Warning,
  Info,
}

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    isDesignTutorialCompleted: false,
    isEditTutorialCompleted: false,
    isTrainTutorialCompleted: false,
    isMainMenuFixed: false,
    showSearchBar: true,
    showImagePopup: false,
    imagePopupItemId: null,
    imagePopupImageIdx: null as any,
    showEditProfileModal: false,
    showChangePasswordModal: false,
    showDeleteAccountModal: false,
    showLogoutModal: false,
    showUserFlow: false,
    isAuthorized: false,
    user: { id: "" as any, name: "", username: "", email: "", work: "", privateSession: 0, createPeriod: 120, profilePhoto: "", packageCode: "", use_ghostphotoshoot: false, use_mannequin: false },
    isCheckingToken: true,
    searchInput: { text: "", image: "" },
    noReset: false,
    snackbar: [] as ISnackbar[],
    collectionFolders: ["Collections"] as string[],
    tutorial: { show: false, type: null as TutorialType | null, data: [] as any },
    isSketchAdded: false,
    accountSelectedMenu: AccountSelectMenu.AccountSettings,
    showUpdateModal: false,
  },
  reducers: {
    setAccountSelectedMenu: (state, action) => {
      state.accountSelectedMenu = action.payload;
    },
    setIsSketchAdded: (state, action) => {
      state.isSketchAdded = action.payload;
    },
    setIsDesignTutorialCompleted: (state, action) => {
      state.isDesignTutorialCompleted = action.payload;
    },
    setIsEditTutorialCompleted: (state, action) => {
      state.isEditTutorialCompleted = action.payload;
    },
    setIsTrainTutorialCompleted: (state, action) => {
      state.isTrainTutorialCompleted = action.payload;
    },
    setTutorial: (state, action) => {
      state.tutorial = action.payload;
    },
    setCollectionFolders: (state, action) => {
      state.collectionFolders = action.payload;
    },
    setIsMainMenuFixed: (state, action) => {
      state.isMainMenuFixed = action.payload;
    },
    toggleSearchBar: (state) => {
      state.showSearchBar = !state.showSearchBar;
    },
    setShowSearchBar: (state, action) => {
      state.showSearchBar = action.payload;
    },
    toggleImagePopup: (state) => {
      state.showImagePopup = !state.showImagePopup;
    },
    setImagePopupItemId: (state, action) => {
      state.imagePopupItemId = action.payload;
    },
    setImagePopupImageIdx: (state, action) => {
      state.imagePopupImageIdx = action.payload;
    },
    toggleEditProfileModal: (state) => {
      state.showEditProfileModal = !state.showEditProfileModal;
    },
    toggleChangePasswordModal: (state) => {
      state.showChangePasswordModal = !state.showChangePasswordModal;
    },
    toggleDeleteAccountModal: (state) => {
      state.showDeleteAccountModal = !state.showDeleteAccountModal;
    },
    toggleLogoutModal: (state) => {
      state.showLogoutModal = !state.showLogoutModal;
    },
    toggleUserFlow: (state) => {
      state.showUserFlow = !state.showUserFlow;
    },
    setShowUserFlow: (state, action) => {
      state.showUserFlow = action.payload;
    },
    setIsAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
    },
    setUser: (state, action) => {
      state.user = {
        id: action.payload.id,
        name: action.payload.name ?? "",
        username: action.payload.username ?? "",
        email: action.payload.email ?? "",
        work: action.payload.work ?? "",
        privateSession: action.payload.privateSession ?? "",
        createPeriod: action.payload.createPeriod ?? 120,
        profilePhoto: action.payload.profilePhoto ?? "",
        packageCode: action.payload.packageCode ?? "",
        use_ghostphotoshoot: action.payload.use_ghostphotoshoot ?? false,
        use_mannequin: action.payload.use_mannequin ?? false,
      };
    },
    signOut: () => {
      window.dispatchEvent(new CustomEvent(EventDispatchLogout));
    },
    setCheckingToken: (state, action) => {
      state.isCheckingToken = action.payload;
    },

    setSearchInput: (state, action) => {
      state.searchInput.text = action.payload.text;
      state.searchInput.image = action.payload.image;
    },
    setNoReset: (state, action) => {
      state.noReset = action.payload;
    },
    setSnackbar: (state, action) => {
      state.snackbar = [
        ...state.snackbar,
        {
          message: action.payload?.message,
          icon: action.payload?.icon,
          type: action.payload?.type,
        },
      ];
    },
    resetSnackbar: (state) => {
      state.snackbar = [];
    },
    showUpdateModal: (state) => {
      state.showUpdateModal = true;
    },
    hideUpdateModal: (state) => {
      state.showUpdateModal = false;
    },
  },
  extraReducers: {},
});

export const {
  setIsSketchAdded,
  setIsDesignTutorialCompleted,
  setIsEditTutorialCompleted,
  setIsTrainTutorialCompleted,
  setTutorial,
  setCollectionFolders,
  setIsMainMenuFixed,
  toggleSearchBar,
  toggleImagePopup,
  setImagePopupItemId,
  setImagePopupImageIdx,
  toggleEditProfileModal,
  toggleChangePasswordModal,
  toggleDeleteAccountModal,
  toggleLogoutModal,
  toggleUserFlow,
  setIsAuthorized,
  setUser,
  setShowUserFlow,
  signOut,
  setCheckingToken,
  setSearchInput,
  setShowSearchBar,
  setNoReset,
  setSnackbar,
  resetSnackbar,
  setAccountSelectedMenu,
  showUpdateModal,
  hideUpdateModal,
} = commonSlice.actions;

export default commonSlice.reducer;
