/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { IconBackArrow, IconCamera, IconDesign, IconExplore, IconExport, IconExternal, IconPlay, IconPlus, IconShare, IconTrain } from "icons";
import CollectionsItem from "pages/CollectionsPage/components/CollectionsItem";
import DesignHistoryItem from "pages/DesignHistoryPage/components/DesignHistoryItem";
import TrainingsItem from "pages/TrainingsPage/components/TrainingsItem";
import { ImageHomeBrushToolScreenshot, ImageResources1, ImageResources2, ImageResources3, ImageResources4, ImageResources5, ImageResources6, ImageResources7 } from "images";
import collectionsService from "api/collections/collections.service";
import designService from "api/design/design.service";
import trainService from "api/train/train.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PATHS } from "router/config/paths";
import HistoryCarousel from "pages/DesignHistoryPage/components/HistoryCarousel";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Button from "components/Button";
import { ITrainEditRequest } from "api/train/train.type";
import OnBoarding from "./components/OnBoarding";
import PaymentSuccessModal from "components/PaymentSuccessModal";
import PaymentFail from "pages/PaymentFail";
import PaymentFailModal from "components/PaymentFailModal";
import { useAppSelector } from "store";
import Pricing from "pages/PricingPage";
import ChoosePlan from "pages/Account/components/ChoosePlan";
import userService from "api/user/user.service";
import useAuthToken from "hooks/useAuthToken";
import { useDispatch } from "react-redux";
import { setIsAuthorized, setUser } from "store/commonSlice";
import { AnalyticsEvent, getClearPath } from "utils";
import TutorialItem from "components/Tutorial/components/TutorialItem";
import { DesignTutorialData } from "components/Tutorial/TutorialData";
import DiscoverItem from "pages/DiscoverPage/components/DiscoverItem";
import staticsService from "api/statics/statics.service";

export const EventCloseImageCarousel = "RefabricCloseImageCarousel";

const EmptyState = ({ text }: any) => (
  <div className="flex-center flex-col gap-5 w-full text-h6 text-grey bg-bg h-40 rounded-md">
    <span>{text}</span>
    <Button className="btn-secondary">1212</Button>
  </div>
);

let limit = { collectionsLimit: 4, trainingsLimit: 4, designHistory: 10 };

export const resources = [
  {
    image: ImageResources1,
    title: "Getting Started",
    description: "Learn the basics of the tool, including how to navigate and begin your design journey.",
    link: "https://youtu.be/-vq8dlpK5Ks?feature=shared",
  },
  {
    image: ImageResources2,
    title: "Generate Your First Design",
    description: "Explore ways to customize and perfect your designs with trending styles, all with AI assistance.",
    link: "https://www.youtube.com/watch?v=gWP5Pn-ceSk&ab_channel=Refabric",
  },
  {
    image: ImageResources3,
    title: "Train AI For Your Style",
    description: "Discover how AI training works and how to customize it to suit your brand’s needs.",
    link: "https://youtu.be/JTFykZpo_hw",
  },
  {
    image: ImageResources4,
    title: "Prompting Tips",
    description: "Get tips to assist you in prompting your ideas into stunning designs.",
    link: "https://explorefeatures.refabric.com/prompting-tips",
  },
  {
    image: ImageResources5,
    title: "Prompt to Design Showcase",
    description: "Explore examples that demonstrate the transformation of creative ideas into stunning designs.",
    link: "https://explorefeatures.refabric.com/prompt-to-design-showcase",
  },
];

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [collections, setCollections] = useState([]);
  const [trainings, setTrainings] = useState<any>([]);
  const [designHistory, setDesignHistory] = useState<any>([]);
  const [disoverItems, setDiscoverItems] = useState<any>([]);
  const [showCarousel, setShowCarousel] = useState(false);
  const [currentDesignIndex, setCurrentDesignIndex] = useState<number>(0);
  const [showPaymentSuccess, setShowPaymentSucces] = useState(false);
  const [showPaymentFail, setShowPaymentFail] = useState(false);
  const { isAuthorized, isCheckingToken, user } = useAppSelector((state) => state.common);
  const [isDiscover, setIsDiscover] = useState(false);
  const [showResources, setShowResources] = useState(true);

  const { orderId } = useParams();

  function fetchData(only = -1) {
    //"only" is for only fetching one of these: collections or trainings or design history
    const _prevLimit = limit;
    const width = window.innerWidth;
    if (width > 1800) limit = { collectionsLimit: 6, trainingsLimit: 6, designHistory: 14 };
    else if (width > 1440) limit = { collectionsLimit: 5, trainingsLimit: 5, designHistory: 12 };
    else if (width > 1024) limit = { collectionsLimit: 4, trainingsLimit: 4, designHistory: 10 };
    else if (width > 834) limit = { collectionsLimit: 3, trainingsLimit: 3, designHistory: 8 };
    else if (width > 430) limit = { collectionsLimit: 2, trainingsLimit: 2, designHistory: 8 };
    else if (width <= 430) limit = { collectionsLimit: 1, trainingsLimit: 1, designHistory: 6 };

    if (_prevLimit.collectionsLimit !== limit.collectionsLimit || only !== -1) {
      switch (only) {
        case 0:
          collectionsService.getCollections({ limit: limit.collectionsLimit }).then((res) => setCollections(res.data));
          break;
        case 1:
          trainService.getTrains({ limit: limit.trainingsLimit }).then((res) => setTrainings(res.data));
          break;
        case 2:
          designService.getDesigns({ limit: limit.designHistory, expanded: 0 }).then((res) => setDesignHistory(res.data));
          break;
        case 3:
          staticsService.getDiscover({ limit: limit.designHistory, expanded: 0 }).then((res) => setDiscoverItems(res.data));
          break;
        default:
          collectionsService.getCollections({ limit: limit.collectionsLimit }).then((res) => setCollections(res.data));
          designService.getDesigns({ limit: limit.designHistory, expanded: 0 }).then((res) => setDesignHistory(res.data));
          trainService.getTrains({ limit: limit.trainingsLimit }).then((res) => setTrainings(res.data));
          staticsService.getDiscover({ limit: limit.designHistory, expanded: 0 }).then((res) => setDiscoverItems(res.data));

          break;
      }
    }
  }

  useEffect(() => {
    if (isCheckingToken) return;

    if (orderId && window.location.pathname.includes("payment/success") && user.email) {
      userService
        .refreshToken({ email: user.email })
        .then((res) => {
          useAuthToken.setAuthTokenFromLocalStorage(res.responseData.token);
          dispatch(setIsAuthorized(true));
        })
        .catch((e) => {
          console.log(e);
          navigate("/");
        });
      setShowPaymentSucces(true);
    } else if (orderId && window.location.pathname.includes("payment/error")) setShowPaymentFail(true);
  }, [user.email, isCheckingToken]);

  useEffect(() => {
    const handleSizeChange = () => {
      fetchData();
    };
    handleSizeChange();

    collectionsService.getCollections({ limit: limit.collectionsLimit }).then((res) => setCollections(res.data));
    designService.getDesigns({ limit: limit.designHistory, expanded: 0 }).then((res) => setDesignHistory(res.data));
    trainService.getTrains({ limit: limit.trainingsLimit }).then((res) => setTrainings(res.data));
    staticsService.getDiscover({ limit: limit.designHistory, expanded: 0 }).then((res) => setDiscoverItems(res.data));

    window.addEventListener("resize", handleSizeChange);

    return () => {
      window.removeEventListener("resize", handleSizeChange);
    };
  }, []);

  useEffect(() => {
    const handleCloseCarousel = (e: CustomEvent) => {
      if (e.type === EventCloseImageCarousel) {
        setShowCarousel(false);
        setCurrentDesignIndex(0);
      }
    };

    window.addEventListener(EventCloseImageCarousel, handleCloseCarousel as EventListener);

    return () => {
      window.removeEventListener(EventCloseImageCarousel, handleCloseCarousel as EventListener);
    };
  }, []);

  function onStartTrain(trainId: string) {
    trainService.startTrain(trainId);
  }

  function onEditTrain(train_id: string, data: ITrainEditRequest) {
    trainService.editTrain(train_id, data).then(() => setTrainings(trainings.map((item: any) => (item.id === train_id ? { ...item, name: data.name } : item))));
  }

  async function onDeleteCollection(collectionId: string) {
    return await collectionsService.deleteCollection(collectionId).then(() => fetchData(0));
  }

  async function onDeleteTrain(trainId: string) {
    return await trainService.deleteTrain(trainId).then(() => fetchData(1));
  }

  async function onDeleteImage(id: string, imageId: string, index: number) {
    return await designService.deleteDesign(id).then(() => fetchData(2));
  }

  return isAuthorized ? (
    <div className="relative flex flex-col h-full w-full">
      <PaymentSuccessModal
        show={showPaymentSuccess}
        onClose={() => {
          navigate("/", { replace: true });
          setShowPaymentSucces(false);
        }}
      />
      <PaymentFailModal
        show={showPaymentFail}
        onClose={() => {
          navigate("/", { replace: true });
          setShowPaymentFail(false);
        }}
      />

      {showCarousel && (
        <HistoryCarousel
          isDiscover={isDiscover}
          designsFromHomePage={isDiscover ? disoverItems : designHistory}
          onClose={() => {
            setShowCarousel(false);
            setCurrentDesignIndex(0);
          }}
          designIndex={currentDesignIndex}
        />
      )}

      <div className="flex flex-col m-5 gap-12">
        <OnBoarding />

        <div className="flex flex-col gap-5">
          <div className="flex items-center justify-between h-[33.6px]">
            {showResources && <h3 className="text-h3 text-white">Resources</h3>}
            <button className="text-green text-bodySm ml-auto" onClick={() => setShowResources((prev) => !prev)}>
              {showResources ? "Hide" : "Unhide"}
            </button>
          </div>

          {showResources && (
            <div className="flex w-full gap-3">
              {resources.map((item, index) => (
                <a className="flex flex-col gap-3 p-3 border border-bg-3 rounded" key={"resourcesitem_" + index} href={item.link} target="_blank" rel="noreferrer">
                  <div className="">
                    <img src={item.image} className="" />
                  </div>
                  <div className="flex items-center justify-between">
                    <h6 className="text-h6 text-white">{item.title}</h6>
                    <IconExternal className="text-white" />
                  </div>
                  <span className="text-bodySm text-grey">{item.description}</span>
                </a>
              ))}
            </div>
          )}
        </div>

        <div className="flex flex-col gap-[30px]">
          <div className="flex justify-between">
            <h3 className="text-h3 text-white">Discover</h3>
            <span
              className="cursor-pointer flex items-center gap-[5px] text-h7 text-green"
              onClick={() => {
                AnalyticsEvent("homepage_design_history_see_all", "", "click");
                navigate(PATHS.DISCOVER);
              }}
            >
              See All
              <IconBackArrow className="w-[18px] h-[18px] rotate-180" />
            </span>
          </div>
          {disoverItems.length === 0 ? (
            <div className="flex-center flex-col gap-5 w-full text-h6 text-grey bg-bg h-40 rounded-md">
              <span>No designs yet</span>
              <Button className="btn-secondary-small w-[146px] h-9" onClick={() => navigate("/design")}>
                <IconPlus className="w-[18px] h-[18px]" /> New Design
              </Button>
            </div>
          ) : (
            <div className="h-full grid grid-cols-3 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 w-full gap-2.5">
              {disoverItems.map((item: any, index: number) => (
                <DiscoverItem
                  key={`Home_Discover_Item_${item.id}_${index}`}
                  data={item}
                  onClick={() => {
                    setIsDiscover(true);
                    setShowCarousel(true);
                    setCurrentDesignIndex(index);
                  }}
                  onDeleteImage={onDeleteImage}
                />
              ))}
            </div>
          )}
        </div>

        <div className="flex flex-col gap-[30px]">
          <div className="flex justify-between">
            <h3 className="text-h3 text-white">My Collections</h3>
            <span
              className="cursor-pointer flex items-center gap-[5px] text-h7 text-green"
              onClick={() => {
                AnalyticsEvent("homepage_collections_see_all", "", "click");
                navigate("/collections");
              }}
            >
              See All
              <IconBackArrow className="w-[18px] h-[18px] rotate-180" />
            </span>
          </div>
          {collections.length === 0 ? (
            <div className="flex-center flex-col gap-5 w-full text-h6 text-grey bg-bg h-40 rounded-md">
              <span>No collections yet</span>
            </div>
          ) : (
            <div className="h-full grid grid-cols-4 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 w-full gap-2.5">
              <DndProvider backend={HTML5Backend}>
                {collections.map((item: any) => (
                  <CollectionsItem key={item.id} data={item} onDeleteCollection={onDeleteCollection} />
                ))}
              </DndProvider>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-[30px]">
          <div className="flex justify-between">
            <h3 className="text-h3 text-white">My Trainings</h3>
            <span
              className="cursor-pointer flex items-center gap-[5px] text-h7 text-green"
              onClick={() => {
                AnalyticsEvent("homepage_mytrainings_see_all", "", "click");
                navigate("/trainings");
              }}
            >
              See All
              <IconBackArrow className="w-[18px] h-[18px] rotate-180" />
            </span>
          </div>
          {trainings.length === 0 ? (
            <div className="flex-center flex-col gap-5 w-full text-h6 text-grey bg-bg h-40 rounded-md">
              <span>No trainings yet</span>
              <Button className="btn-secondary-small w-[146px] h-9" onClick={() => navigate("/new-training")}>
                <IconPlus className="w-[18px] h-[18px]" /> New Training
              </Button>
            </div>
          ) : (
            <div className="h-full grid grid-cols-4 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 w-full gap-2.5">
              <DndProvider backend={HTML5Backend}>
                {trainings.map((item: any) => (
                  <TrainingsItem
                    key={item.id}
                    data={item}
                    onStartTrain={() => onStartTrain(item.id)}
                    onEditTrain={(_: any, data: ITrainEditRequest) => {
                      onEditTrain(item.id, data);
                    }}
                    onDeleteTrain={onDeleteTrain}
                  />
                ))}
              </DndProvider>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-[30px]">
          <div className="flex justify-between">
            <h3 className="text-h3 text-white">Design History</h3>
            <span
              className="cursor-pointer flex items-center gap-[5px] text-h7 text-green"
              onClick={() => {
                AnalyticsEvent("homepage_design_history_see_all", "", "click");
                navigate(PATHS.DESIGN_HISTORY);
              }}
            >
              See All
              <IconBackArrow className="w-[18px] h-[18px] rotate-180" />
            </span>
          </div>
          {designHistory.length === 0 ? (
            <div className="flex-center flex-col gap-5 w-full text-h6 text-grey bg-bg h-40 rounded-md">
              <span>No designs yet</span>
              <Button className="btn-secondary-small w-[146px] h-9" onClick={() => navigate("/design")}>
                <IconPlus className="w-[18px] h-[18px]" /> New Design
              </Button>
            </div>
          ) : (
            <div className="h-full grid grid-cols-5 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 w-full gap-2.5">
              {designHistory.map((item: any, index: number) => (
                <DesignHistoryItem
                  key={`Home_DesignHistory_Item_${item.id}_${index}`}
                  data={item}
                  onClick={() => {
                    setShowCarousel(true);
                    setCurrentDesignIndex(index);
                  }}
                  onDeleteImage={onDeleteImage}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : !isCheckingToken ? (
    <div className="flex-1 h-screen pb-12">
      <ChoosePlan />
    </div>
  ) : (
    <></>
  );
};

export default Home;
