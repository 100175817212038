export const TUTORIAL_DESIGN_COMPLETED = "tutorial_design_completed";
export const TUTORIAL_EDIT_COMPLETED = "tutorial_edit_completed";
export const TUTORIAL_TRAIN_COMPLETED = "tutorial_train_completed";
export const DOWNLOAD_SETTING = "refabric_download_setting";
export const SHORT_TUTORIAL_DESIGN_SHOWN = "short_tutorial_design_shown";
export const SHORT_TUTORIAL_EDIT_SHOWN = "short_tutorial_edit_shown";
export const SHORT_TUTORIAL_TRAINING_SHOWN = "short_tutorial_training_shown";

export const useLocalStorage = () => {
  return {
    setItem: (key: string, data: any) => {
      localStorage.setItem(key, JSON.stringify(data));
    },
    getItem: (key: string) => {
      try {
        const data = localStorage.getItem(key);

        return JSON.parse(data as string);
      } catch {
        return null;
      }
    },
    removeItem: (key: string) => {
      localStorage.removeItem(key);
    },
  };
};
